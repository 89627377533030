<template>
    <v-row>
        <v-col>
            <v-text-field v-model="filters.channel" dense solo label="Canal" hide-details="auto"></v-text-field>
        </v-col>

        <v-col>
            <v-select v-model="filters.watchman" dense solo :items="readings_watchmans_options" item-text="name"
                item-value="code" label="Por Celador" hide-details="auto"></v-select>
        </v-col>

    </v-row>
</template>

<script>
export default {
    name: 'irrigator-list-filter',
    props: {
        filters: {
            type: Object
        },
        readings_watchmans_options: {
            type: Array
        }
    },
};
</script>
